
<div class="slider-outer" *ngIf="sliders">
  <div
    id="welcome_wrapper"
    class="rev_slider_wrapper fullscreen-container"
    data-alias="goodnews-header"
    data-source="gallery"
    style="background: #eeeeee; padding: 0px; height: 100vh !important"
  >
    <div
      id="welcome124241"
      class="rev_slider fullscreenbanner"
      style="display: none"
      data-loop="true"
      data-version="5.4.3.1"
    >
      <ul *ngIf="sliders != null">
        <!-- SLIDE 1 -->
        <li
          *ngFor="let slider of sliders ;let i = index"
          class="overlay"
          [attr.data-index]="'rs-' + (901 + i)"
          data-delay="90000"
          data-transition="fade"
          data-slotamount="default"
          data-hideafterloop="0"
          data-hideslideonmobile="off"
          data-easein="default"
          data-easeout="default"
          data-masterspeed="default"
          attr.data-thumb="{{ isMobile !=true ?slider.image :'hhttps://ubys.ankara.edu.tr/Resources/Cms/10-07-2024/10-07-2024-15-20-59-notice.jpeg'}}"
          data-rotate="0"
          data-fstransition="fade"
          data-fsmasterspeed="10"
          data-fsslotamount="1"
          data-saveperformance="off"
          data-title="Slide Title"
          data-param1="Additional Text"
          data-param2=""
          data-param3=""
          data-param4=""
          data-param5=""
          data-param6=""
          data-param7=""
          data-param8=""
          data-param9=""
          data-param10=""
          data-description=""
        >
          <!-- MAIN IMAGE -->
           <a *ngIf="isMobile !=true"  href="{{slider.url}}" target="_blank">
          <img
          style="width: 100%;"
            src="{{ slider.image}}"
            attr.data-lazyload="{{  slider.image}} "
            alt=""
            attr.data-thumb="{{ slider.image }}"
            data-bgposition="center center"
            data-bgfit="cover"
            data-bgparallax="4"
            class="rev-slidebg"
            data-no-retina
          />
           </a>

            <img *ngIf="isMobile ==true"
              src="https://ubys.ankara.edu.tr/Resources/Cms/10-07-2024/10-07-2024-15-20-59-notice.jpeg"
              data-lazyload="https://ubys.ankara.edu.tr/Resources/Cms/10-07-2024/10-07-2024-15-20-59-notice.jpeg"
              alt=""
              data-thumb="https://ubys.ankara.edu.tr/Resources/Cms/10-07-2024/10-07-2024-15-20-59-notice.jpeg"
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgparallax="4"
              class="rev-slidebg"
              data-no-retina
            />


          <div
          *ngIf="slider.videoPath && !isMobile "
          class="rs-background-video-layer"
          attr.data-videomp4="{{slider.videoPath}}"
          data-videopreload="auto"
          data-volume="100"
          data-forcerewind="on"
          data-videoattributes="loop=loop"
          data-nextslideatend="false"
          data-videoloop="loop"
          data-autoplayonlyfirsttime="true"
          data-autoplay="true"
        ></div>


        <!-- LAYER NR. 5 [ for see all service botton ] -->
        <div *ngIf="slider.url != '#' && !slider.url.startsWith('https://online.fliphtml5.com') && slider.url.indexOf('https://online.fliphtml5.com') === -1"
          class="tp-caption tp-resizeme btn-aday "
          id="slide-901-layer-5"
          data-x="['center','center','center','center']"
          data-hoffset="['100','100','0','0']"
          data-y="['middle','middle','middle','middle']"
          data-voffset="['200','170','100','40']"
          data-lineheight="['none','none','none','none']"
          data-width="['300','300','300','300']"
          data-height="['none','none','none','none']"
          data-whitespace="['normal','normal','normal','normal']"
          data-type="text"
          data-responsive_offset="on"
          data-frames='[
{"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
{"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
]'
          data-textAlign="['left','left','center','center']"
          data-paddingtop="[0,0,0,0]"
          data-paddingright="[0,0,0,0]"
          data-paddingbottom="[0,0,0,0]"
          data-paddingleft="[0,0,0,0]"
          style="z-index: 13; text-transform: uppercase; top:10vh "
        >
          <a href="{{ slider.url }}" class="site-button site-btn-effect btn-aday"
            >{{ slider.baslik }}</a
          >
        </div>
        </li>
      </ul>
      <div
        class="tp-bannertimer tp-bottom"
        style="visibility: hidden !important"
      ></div>
    </div>
  </div>
</div>



